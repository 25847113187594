import React from "react";
import { Grid, Typography } from "@mui/material";

import Icon from "../components/Icon";
export default function BalzacAdvantage() {
  return (
    <Grid
      container
      justifyContent={"space-evenly"}
      alignItems={"flex-start"}
      mb={{ xs: 1, md: "5rem" }}
      mt={{ xs: 1, md: "5rem" }}
      pl={{ xs: 4, sm: 10 }}
      pr={{ xs: 4, sm: 10 }}
      rowSpacing={{ xs: 2, md: 5 }}
    >
      {[
        {
          icon: "MarketReach",
          copy: "Reach a market of more than 50 million+ in one transportation day or less",
        },
        { icon: "Multimodal", copy: "Outstanding multimodal linkages to North American markets" },
        { icon: "Infrastructure", copy: "State-of-the-art infrastructure" },
        {
          icon: "TaxSavings",
          copy: "+/- 50% savings in property tax compared to City of Calgary",
        },
        {
          icon: "DriveLocal",
          copy: "YYC: 10 minute drive times. Downtown Calgary, CP and CN Intermodal Yards: 20-25 minute drive times",
        },
        {
          icon: "DriveHighway",
          copy: "Key drive times: Vancouver - 11 hours, Winnipeg - 12 hours, Seattle - 12 hours, Denver - 17 hours, Los Angeles - 23 hours",
        },
        {
          icon: "Intersect",
          copy: "The intersection of Stoney Trail and Dwight Mclellan blvd. provides the best access. Stoney Trail also intersects with Highway Two/CANAMEX Corridor (north/south).",
        },
        {
          icon: "Airport",
          copy: "Over 4,300 cargo landings and 88,869 tonnes of aviation freight through Calgary International Airport",
        },
      ].map(({ icon, copy }, index) => {
        return (
          <Grid key={copy} item xs={12} sm={6} md={3} p={{ xs: 2, md: 4 }}>
            <Icon icon={icon} />
            <Typography color="common.black">{copy}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}
