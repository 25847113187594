import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Box } from "@mui/material";

import LogisticsLeaders from "../components/LogisticsLeaders";
import BalzacAdvantage from "../components/BalzacAdvantage";
import Facts from "../components/Facts";
import AdvantageHero from "../components/AdvantageHero";
export default function Advantages() {
  return (
    <>
      <Box backgroundColor={"common.lightBlack"} pb={{ xs: 3, md: "4rem" }} pt={{ xs: 2, md: 6 }}>
        <AdvantageHero />
        <LogisticsLeaders />
      </Box>
      <Typography
        align="center"
        variant={"h1"}
        paragraph
        color="primary.main"
        mt={{ xs: 4, md: "5rem" }}
      >
        The Rocky View/Balzac Advantage
      </Typography>

      <Typography align="center" pl={{ xs: 4, sm: 10 }} pr={{ xs: 4, sm: 10 }}>
        Balzac in Rocky View County is Western Canada’s premier location for logistics to service
        all of Western Canada and the U.S. Pacific Northwest.
      </Typography>
      <BalzacAdvantage />
      <Facts />
    </>
  );
}
