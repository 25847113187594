import React from "react";
import styled from "@emotion/styled";

import Airport from "../Icons/Airport.svg";
import DriveHighway from "../Icons/DriveHighway.svg";
import DriveLocal from "../Icons/DriveLocal.svg";
import Infrastructure from "../Icons/Infrastructure.svg";
import Intersect from "../Icons/Intersect.svg";
import MarketReach from "../Icons/MarketReach.svg";
import Multimodal from "../Icons/Multimodal.svg";
import TaxSavings from "../Icons/TaxSavings.svg";
import { OptionUnstyled } from "@mui/base";

/**
 *
 *
 * @export
 * @param {*} { icon, options = { width: "60px", height: "60px" }, ...props }
 * include unit when setting dimensions in options object eg "2rem", "20%"
 * @return {*}
 */
export default function Icon({ icon, options = { width: "60px", height: "60px" }, ...props }) {
  const getIcon = icon => {
    switch (icon) {
      case "Airport":
        return Airport;

      case "DriveHighway":
        return DriveHighway;

      case "DriveLocal":
        return DriveLocal;

      case "Infrastructure":
        return Infrastructure;

      case "Intersect":
        return Intersect;

      case "MarketReach":
        return MarketReach;

      case "Multimodal":
        return Multimodal;

      case "TaxSavings":
        return TaxSavings;

      default:
        return false;
    }
  };

  return <Wrapper src={getIcon(icon)} alt="" role="presentation" $options={options} />;
}

const Wrapper = styled("img")`
  width: ${({ $options }) => $options.width};
  height: ${({ $options }) => $options.height};
`;
