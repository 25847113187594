import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const DefaultUnorderedList = ({
  paragraph,
  align,
  style,
  listColumns,
  markerstyle,
  variant,
  ...props
}) => {
  return (
    <StyledUnorderedList
      className="md"
      style={listColumns ? { columns: `${listColumns} auto` } : { columns: "1 auto" }}
      markerstyle={markerstyle}
    >
      {props.children.map((child, index) => {
        //   return child;

        return (
          child?.props?.children[0] && (
            <li key={child?.props?.children[0]}>
              <Typography
                className="md"
                style={style}
                variant={variant ? variant : "body1"}
                align={align ? align : "left"}
              >
                {child?.props?.children[0]}
              </Typography>
            </li>
          )
        );
      })}
    </StyledUnorderedList>
  );
};

const StyledUnorderedList = styled.ul`
  padding-inline-start: 1.1rem;

  li {
    ::marker {
      color: ${props =>
        props?.markerstyle?.color ? props.markerstyle.color : props.theme.palette.text.primary};
    }
  }
`;
export default DefaultUnorderedList;
