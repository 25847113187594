import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Unstable_Grid2 as Grid, Button, ButtonGroup } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
function Menu({ logo, handleSubMenuClick, handleMenuClick, menuData, subMenuState, ...props }) {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        pt={{ sm: 1, md: 2 }}
        pl={{ sm: 2 }}
        height={{ md: 100 }}
      >
        <Grid xs={3} display="flex" justifyContent="center" alignItems="center">
          <Button variant="text" to="/" component={NavLink}>
            <img
              src={`https://www.stoneynorth.com${logo}`}
              style={{ width: "auto", height: "3.5rem" }}
            />
          </Button>
        </Grid>
        <Grid
          xs={6}
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-end"
          flexDirection="column"
          // pt={{ sm: 1, md: 2 }}
        >
          <Grid container>
            {menuData.map(({ name, title, to, subMenu, id }, index) => {
              return (
                <ButtonWrapper
                  key={name}
                  component={NavLink}
                  onClick={subMenu ? handleSubMenuClick : handleMenuClick}
                  id={name}
                  to={to}
                  variant="text"
                  size="small"
                  endIcon={
                    subMenu && subMenuState[name] === false ? (
                      <ArrowRightIcon />
                    ) : subMenu && subMenuState[name] === true ? (
                      <ArrowDropDownIcon />
                    ) : null
                  }
                >
                  {name}
                </ButtonWrapper>
              );
            })}
          </Grid>
          {menuData.map(({ name, title, to, subMenu, id }, index) => {
            if (subMenu) {
              return (
                subMenuState[name] === true && (
                  <Grid key={to} container width={312}>
                    {subMenu.map(({ name, title, to, subMenu, id }, index, mappedArray) => {
                      return (
                        <ButtonWrapper
                          key={name + name}
                          component={NavLink}
                          onClick={subMenu ? handleSubMenuClick : null}
                          id={name}
                          variant="text"
                          to={subMenu ? null : to}
                          size="small"
                          // style={
                          //   index + 1 < mappedArray.length
                          //     ? { borderRight: "1px solid rgba(197,32,49,1)" }
                          //     : null
                          // }
                        >
                          {name}
                        </ButtonWrapper>
                      );
                    })}
                  </Grid>
                )
              );
            }
          })}
        </Grid>
      </Grid>
    </>
  );
}

const ButtonWrapper = styled(Button)`
  margin-left: 1rem;
  margin-right: 1rem;
  &.MuiButton-text {
    color: black;
  }
  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
Menu.propTypes = {};

export default Menu;
