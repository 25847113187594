import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import {
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  Button,
  Link as MUILink,
  useScrollTrigger,
  Slide,
  Drawer,
} from "@mui/material";
import { Event } from "../Tracking";
import { Menu, Close } from "@mui/icons-material";

import { motion, useViewportScroll } from "framer-motion";
import NavItem from "./NavItem";
import { Link } from "react-router-dom";
//TODO consolidate calendly stuff to one location. Currently multiple implementations

export default function DrawerMenu({ logo, cmsEndpoint, menuData, ...props }) {
  const theme = useTheme();

  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useViewportScroll();

  useEffect(() => {
    scrollYProgress.onChange(v => {
      setProgress(v * 100);
    });
  }, [scrollYProgress]);

  const [open, setOpen] = React.useState(false);
  const handleToggle = event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(!open);
  };

  const createEvent = event => {
    // console.log(e.currentTarget.text);
    Event("Nav", "click", event.currentTarget.text);
  };

  const handleOnClick = event => {
    createEvent(event);
    props.scrollToTop();
  };

  const HideOnScroll = props => {
    let appear = false;
    const trigger = useScrollTrigger({ threshold: 50, disableHysteresis: true });
    const app = document?.getElementById("StoneyNorthApp")?.offsetHeight;
    const bottom = app - window.innerHeight - 180;

    if (window.scrollY > bottom) {
      appear = true;
    }
    return (
      <Slide appear={false} in={!trigger || appear}>
        {props.children}
      </Slide>
    );
  };
  return (
    // <HideOnScroll>
    <AppBar position="fixed">
      <div
        // className="progress-icon"

        style={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <motion.div
          id="motionPath"
          style={{
            position: "relative",

            // right: progress2 + "%",
            backgroundColor: theme.palette.primary.main,
            opacity: progress + 20 + "%",
            height: 2,
            borderRight: `5px solid ${theme.palette.primary.yellow}`,
            zIndex: 999,
            width: progress + "%",
            // pathLength: "500px",
            // Reverse direction of line animation
          }}
        />
      </div>
      <StyledToolbar>
        {/* TODO Change Logo */}
        <NavItem activeClassName={"activeMobile"} href="/" onClick={handleOnClick}>
          <Logo alt="Stoney North Logo" role="presentation" src={`${cmsEndpoint}${logo}`} />
        </NavItem>

        <IconButton
          //MuiIconButton-root is set in StyledToolbar
          edge="start"
          backgroundColor="transparent"
          aria-label="open drawer"
          onClick={handleToggle}
        >
          <Menu style={{ width: "1.3em", height: "1.3em" }} />
        </IconButton>

        {/* <StyledBlock hidemobile={false}></StyledBlock> */}
      </StyledToolbar>
      {/* TODO close drawer when window is resized */}
      <Drawer
        open={open}
        // containerStyle={styles.navBar}
        PaperProps={{ component: StyledPaper }}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        role="presentation"
        anchor="right"
        id="Drawer"
      >
        <Close style={{ alignSelf: "flex-end" }}></Close>
        <DrawerNavContainer id="drawer-nav-container">
          <NavItem href="/" onClick={props.handleNavClick} activeClassName={""}>
            <DrawerLogo src={`${cmsEndpoint}${logo}`}></DrawerLogo>
          </NavItem>

          {menuData.map(({ to, name, title, subMenu, ...item }, index, items) => {
            return (
              <>
                {!subMenu && (
                  <NavItem
                    key={to}
                    href={to}
                    id={name}
                    handleOnClick={handleOnClick}
                    activeClassName="activeMobile"
                  >
                    {name}
                  </NavItem>
                )}
                {subMenu &&
                  subMenu.map(({ to, name, title, subMenu, ...item }, index, items) => {
                    return (
                      <NavItem
                        key={to}
                        href={to}
                        id={name}
                        handleOnClick={handleOnClick}
                        activeClassName="activeMobile"
                      >
                        {name}
                      </NavItem>
                    );
                  })}
              </>
            );
          })}
        </DrawerNavContainer>
      </Drawer>
    </AppBar>
    // </HideOnScroll>
  );
}

const StyledPaper = styled("div")`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 26%),
    center 100% / 113% auto no-repeat url();
  padding-top: 2em;
  /* padding-left: 2em; */
  padding-right: 2em;
  width: 300px;
  @media (min-width: 480px) {
    width: 300px;
  }
  /* border-right: ${props => props.theme.mediumBlue} solid 5px; */
`;
const DrawerNavContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  padding: 0 0 3em 3em;
  flex: 1;
  @media (min-width: 480px) {
    width: 100%;
  }
  /* max-height: 500px; */
  a.active {
    background-color: transparent;
    text-decoration: none;
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }

  .MuiButton-contained {
    margin-top: 1rem;
    background-color: #f50057;
    padding: 1rem 1rem;
  }
  .MuiButton-label {
    font-size: 1rem;
    font-style: normal;
    color: ${props => props.theme.palette.common.white};
  }
  a {
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    margin: 0.5em;
    color: ${props => props.theme.palette.common.black};
    font-weight: ${props => props.theme.typography.fontWeightBold};
    &.activeMobile {
      color: ${props => props.theme.palette.primary.yellow};
    }
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }
`;
// const StyledAppBar = styled(AppBar)``;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  height: 58px;
  /* max-height: 55px; */
  /* margin-bottom: 25px; */

  z-index: 500;
  @media (min-width: 480px) {
    // background-color: ${props => props.theme.darkBlue};
    height: 66px;
    /* max-height: 100px; */
  }
  .MuiIconButton-root {
    margin-right: 1.5rem;
  }
`;

const DrawerLogo = styled("img")`
  /* max-height: 15; */
  height: 2rem;
  /* margin-bottom: 2em; */
`;
const Logo = styled("img")`
  max-height: 36px;
  width: auto;
  height: 3rem;

  @media (min-width: 480px) {
    margin-top: 0.3em;
  }
`;
