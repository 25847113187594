import React from "react";
import PropTypes from "prop-types";

import Hero from "../images/ContactHero.jpg";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import ContactBlock from "../components/ContactBlock";
function Contact({ data = [], ...props }) {
  return (
    <>
      <HeroWrapper src={Hero} alt="Contact Stoney North" />
      <ContactBlock data={data} />
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems={"center"}
        pt={"5rem"}
        pb={"5rem"}
        pl={{ xs: 4, md: 8 }}
        pr={{ xs: 4, md: 8 }}
      >
        <Typography variant="h1" color="primary.main" mb={3}>
          ABOUT ENRIGHT CAPITAL
        </Typography>
        <Typography align="left" mb={6}>
          Enright Capital Ltd. develops best-of-class industrial projects for core portfolios. We
          have extensive experience in building greenfield business parks and stand-alone industrial
          buildings on behalf of our institutional partners. Enright meets occupiers’ strategic real
          estate needs by creating master-planned business parks with premium locations and
          best-of-class construction. Our full product cycle expertise ranges from planning and
          zoning to land development, building design, vertical construction, leasing and property
          management.
        </Typography>
        <Typography variant="h1" color="primary.main" mb={3}>
          ABOUT GWL REALTY ADVISORS
        </Typography>
        <Typography align="left">
          As a wholly owned subsidiary of the Canada Life Assurance Company, GWL Realty Advisors
          Inc. is a leading international real estate advisor focused on growth and delivering
          stable, long-term returns for our clients. We provide asset management, property
          management, development, and customized real estate advisory services to over 170 pension
          funds and institutional clients. Our team has extensive experience in all facets of real
          estate investment, management, and development, specializing in office, multi-residential,
          industrial, retail, and mixed-use assets as well as having a dynamic pipeline of new
          development projects. In Canada we employ 780 people with assets under management totaling
          approximately $15 billion.
        </Typography>
      </Grid>
    </>
  );
}
const HeroWrapper = styled("img")`
  width: 100%;
  height: auto;
`;

Contact.propTypes = {};

export default Contact;
