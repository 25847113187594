import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, useTheme } from "@mui/material";
function ContactBlock({ data = [], ...props }) {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      backgroundColor={theme.palette.primary.main}
      pt={"5rem"}
      pb={"5rem"}
      pl={{ xs: 4, md: 8 }}
      pr={{ xs: 4, md: 8 }}
      rowSpacing={4}
    >
      <>
        <Typography
          variant="h2"
          width="100%"
          color="white"
          fontWeight={"bold"}
          mb={"2rem"}
          align="left"
        >
          FOR MORE INFORMATION OR INQUIRIES, PLEASE CONTACT:
        </Typography>
        {data?.map(({ Name, DirectPhone, MobilePhone, email }, index) => {
          // debugger;
          return (
            <Grid key={Name} item xs={12} sm={6} md={4} align="left" color="white">
              <Typography fontWeight={"bold"} textTransform="uppercase">
                {Name}
              </Typography>
              <Typography>T: {DirectPhone}</Typography>
              <Typography>M: {MobilePhone}</Typography>
              <Typography>{email}</Typography>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} md={3} align="left" color="white">
          <Typography fontWeight={"bold"}>Enright</Typography>
          <Typography>800, 110 12 Avenue SW</Typography>
          <Typography>Calgary, AB, T2R 0G7</Typography>
          <Typography>T: 403.228.2913</Typography>
        </Grid>
      </>
    </Grid>
  );
}

ContactBlock.propTypes = {};

export default ContactBlock;
