import React from "react";
import styled from "@emotion/styled";

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import WarehouseWorker from "../images/WarehouseWorker.jpg";
export default function AdvantageHero() {
  return (
    <Box>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <ImageWrapper item xs={12} md={4.2} />
        <Grid
          item
          xs={12}
          md={7}
          pl={{ xs: 4, sm: 10 }}
          pr={{ xs: 4, sm: 10 }}
          pt={{ xs: 6, md: 0 }}
        >
          <Typography variant={"h1"} color="primary.main" align="left" mb={{ xs: 2, md: 4 }}>
            Favorable Taxation <br />
            Makes the Difference
          </Typography>
          <Typography textAlign={"left"} pr={{ xs: 0, md: 10 }}>
            Compared to the City of Calgary, Rocky View County has a mill rate that is approximately
            50 percent less and the County does not assess a business tax. Rocky View County will
            save industrial users +/- $1.50 per square foot in operating expenditures per year, a
            tax savings of +/-50 percent over a ten-year lease compared to the City of Calgary. For
            a 500,000 SF building, the estimated savings can be approximately $735,000 per year or
            more than $7M over a 10-year term.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
const ImageWrapper = styled(Grid)`
  position: relative;
  height: 160px;
  background-size: cover;
  background-position: center;
  background-image: url(${WarehouseWorker});
  ${({ theme }) => theme.breakpoints.up("sm")} {
    height: 250px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 450px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 585px;
  }
`;
