import { theme } from "../Theme";
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "auto" });
};

export const convertColor = color => {
  if (color === "Primary") {
    return theme.palette.primary.main;
  }
  if (color === "Secondary") {
    return theme.palette.secondary.main;
  }
  if (color === "PrimaryLight") {
    return theme.palette.primary.light;
  }
  if (color === "SecondaryLight") {
    return theme.palette.secondary.light;
  }
  if (color === "PrimaryDark") {
    return theme.palette.primary.dark;
  }
  if (color === "SecondaryDark") {
    return theme.palette.secondary.dark;
  }
  return color;
};

const pageAnimations = {
  hidden: {
    opacity: 0.05,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },

  exit: { opacity: 0.0, transition: { duration: 0.25 } },
};
