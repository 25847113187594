import { Grid, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import AeconLogo from "./logos/AeconLogo.png";
import AmazonLogo from "./logos/AmazonLogo.png";
import ConagraLogo from "./logos/ConagraLogo.png";
import GordonFoodServiceLogo from "./logos/GordonFoodServiceLogo.png";
import LowesLogo from "./logos/LowesLogo.png";
import SMUCKERSLogo from "./logos/SMUCKERSLogo.png";
import SobeysLogo from "./logos/SobeysLogo.png";
import TJMaxxLogo from "./logos/TJMaxxLogo.png";
import WalmartLogo from "./logos/WalmartLogo.png";
import WinnersLogo from "./logos/WinnersLogo.png";
import { Box } from "@mui/system";
export default function LogisticsLeaders() {
  return (
    <Box mb={{ xs: 3, md: "5rem" }} mt={{ xs: 7, md: 12 }}>
      <Typography variant={"h2"} color="common.backgroundGrey">
        Join these logistics leaders
      </Typography>
      <Grid
        container
        justifyContent={"space-evenly"}
        alignItems={"center"}
        mb={{ xs: 3, md: "5rem" }}
        mt={{ xs: 3, md: "1rem" }}
        pl={4}
        pr={4}
        rowSpacing={5}
      >
        {[
          { file: SMUCKERSLogo, url: "", name: "" },
          { file: ConagraLogo, url: "", name: "" },
          { file: LowesLogo, url: "", name: "" },
          { file: AmazonLogo, url: "", name: "" },
          { file: GordonFoodServiceLogo, url: "", name: "" },
          { file: WinnersLogo, url: "", name: "" },
          { file: SobeysLogo, url: "", name: "" },
          { file: AeconLogo, url: "", name: "" },
          { file: WalmartLogo, url: "", name: "" },
          { file: TJMaxxLogo, url: "", name: "" },
        ].map(({ file, name }, index) => {
          return (
            <Grid key={file} item xs={6} sm={3} md={2.4}>
              {<ImageWrapper src={file} alt={name} role="presentation" />}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
const ImageWrapper = styled("img")`
  width: 100px;
  height: auto;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 150px;
    height: auto;
  }
`;
