import React from "react";
import styled from "@emotion/styled";

import { Typography, Unstable_Grid2 as Grid } from "@mui/material";

export default function Maps({ map, ...props }) {
  return (
    <>
      {/* TODO Add pdf links */}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={{ xs: "column-reverse", md: "row" }}
        backgroundColor="#F5F6F6"
      >
        <Grid
          xs={10}
          md={5}
          pl={{ xs: 1, md: 3 }}
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography
            variant="h2"
            color="primary.main"
            align="left"
            mb={{ xs: 2, md: 4 }}
            // ml={{ md: 9.5 }}
            style={{ width: "100%", maxWidth: 325 }}
          >
            Legend
          </Typography>
          <LegendWrapper src={`https://www.stoneynorth.com${map?.Image[1]?.url}`} alt="Legend" />
        </Grid>
        <Grid xs={12} md={7} pb={{ xs: 6, md: 0 }}>
          <MapWrapper src={`https://www.stoneynorth.com${map?.Image[0]?.url}`} alt="Map" />
        </Grid>
      </Grid>
    </>
  );
}

const MapWrapper = styled("img")`
  width: 100%;
  height: auto;
`;
const LegendWrapper = styled("img")`
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  max-width: 90vw;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 325px;
  }
`;
