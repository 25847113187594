import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

import RenderMarkdown from "../components/RenderMarkdown";
import SNLCLogo from "../components/svg/SNLCLogoWithBG.svg";
// import { initGA, PageView } from "./components/Tracking";

import LoadingDock from "../images/LoadingDock.webp";

export default function Home({ projectDescription, ...props }) {
  return (
    <>
      <HeroWrapper
        container
        justifyContent={"center"}
        alignContent={"space-between"}
        pt={{ xs: "1rem" }}
        pb={{ xs: 5, md: 16 }}
        height={{ xs: 375, sm: 580 }}
      >
        <Grid item xs={10}>
          <Typography
            variant={"h5"}
            fontSize={{
              xs: "1.23rem",
              sm: "24px",
              md: "30px",
            }}
            color="white"
            align="center"
            fontWeight={"600"}
          >
            YOUR INDUSTRIAL ADVANTAGE IS STONEY NORTH
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant={"h1"}
            color="white"
            align="right"
            fontSize={{
              xs: "1.8125rem",
              sm: "3.4375rem",
            }}
          >
            2.2 MILLION SF OF INDUSTRIAL DISTRIBUTION SPACE IN BALZAC
          </Typography>
        </Grid>
      </HeroWrapper>
      <ComingSoonWrapper container justifyContent={"space-evenly"}>
        <Grid
          item
          xs={12}
          md={4}
          pt={8}
          pb={{ xs: 2, md: 8 }}
          pl={{ xs: 4, sm: 10 }}
          pr={{ xs: 4, sm: 10 }}
        >
          <Typography
            align="left"
            variant={"h1"}
            color="white"
            fontSize={{
              xs: "1.8125rem",
              sm: "3.4375rem",
            }}
            dangerouslySetInnerHTML={{ __html: projectDescription.occupancy }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          pt={{ xs: 2, md: 8 }}
          pb={8}
          pl={{ xs: 4, sm: 10 }}
          pr={{ xs: 4, sm: 10 }}
        >
          <Typography
            color="white"
            align="left"
            dangerouslySetInnerHTML={{ __html: projectDescription.bodyCopy }}
          />
        </Grid>
      </ComingSoonWrapper>
    </>
  );
}
const HeroWrapper = styled(Grid)`
  background-image: url(${SNLCLogo}), url(${LoadingDock});
  background-size: 120px, cover;
  background-repeat: no-repeat;
  background-position: right 1rem top 5rem, center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-position: right 1rem top 1rem, center;
  }
`;
const ComingSoonWrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.grey.backgroundGrey};
`;
