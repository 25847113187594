import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { initGA, PageView } from "./components/Tracking";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { Routes, Route, Link, Navigate } from "react-router-dom";

import "./App.css";

import Menu from "./components/Menu";
import DrawerMenu from "./components/DrawerMenu";
import Home from "./pages/Home";
import Advantages from "./pages/Advantages";
import Leasing from "./pages/Leasing";
import Maps from "./pages/Maps";
import Contact from "./pages/Contact";
import NoMatch from "./pages/NoMatch";
import Footer from "./components/Footer";
import { scrollToTop, convertColor, pageAnimations } from "./components/Functions";
import { menuData } from "./components/Menu/data";
const cmsEndpoint = "https://www.stoneynorth.com";

function App() {
  const [loadingData, setLoadingData] = React.useState(false);
  const [showMap, setShowMap] = React.useState(false);
  const [showLeasing, setShowLeasing] = React.useState(false);
  const [buildingData, setBuildingData] = useState({});
  const [projectLogo, setProjectLogo] = useState();
  const [buildingSpecifications, setBuildingSpecifications] = useState({
    building1: {
      data: [""],
    },
  });

  const [notes, setNotes] = useState({
    landlord: {
      notes: "",
      title: "",
    },
    amenities: {
      notes: "",
      title: "",
    },
  });
  const [galleryImages, setGalleryImages] = useState({ data: ["", ""] });
  const [projectDescription, setProjectDescription] = useState({
    name: "",
    projectName: "",
    title: "",
    filePrefix: "",
    logo: "",
    address1: "",
    area1: "",
    totalArea: "",
    occupancy: "",
    bodyCopy: "",
    projectPDF: "",
  });
  const [landingImages, setLandingImages] = useState({ data: [[]] });

  const [locationMapData, setLocationMapData] = useState();
  const [areaMapData, setAreaMapData] = useState();
  const [siteMapData, setSiteMapData] = useState();
  const [contactData, setContactData] = useState();
  const [subMenuState, setSubMenuState] = useState({});

  // useEffect(() => {
  //   initGA("G-C0YS2KEGQL");
  // }, []);
  // const [menuData, setMenuData] =useState('')
  // useEffect(() => {
  //   PageView(window.location.pathname + window.location.search);
  // }, []);

  const handleSubMenuClick = event => {
    const clickedId = event.currentTarget.id;
    // setSubMenuState(prev => {
    //   return { ...prev, [event.currentTarget.id]: !prev[event.currentTarget.id] };
    // });

    Object.keys(subMenuState).forEach((key, index) => {
      setSubMenuState(prev => {
        return { ...prev, [key]: false };
      });
      return true;
    });

    setSubMenuState(prev => {
      return { ...prev, [clickedId]: true };
    });
  };
  const handleMenuClick = event => {
    Object.keys(subMenuState).forEach((key, index) => {
      setSubMenuState(prev => {
        return { ...prev, [key]: false };
      });
      return true;
    });
    // setSubMenuState(prev => {
    //   return { ...prev, [event.currentTarget.id]: !prev[event.currentTarget.id] };
    // });
  };

  React.useEffect(() => {
    if (subMenuState.length > 0) {
      return true;
    }
    menuData.map((menu, index) => {
      if (menu.hasOwnProperty("subMenu")) {
        setSubMenuState(prev => {
          return { ...prev, [menu.name]: false };
        });
      }
    });
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: "https://cms.canal108.ca/projects/4",
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoadingData(true);

    axios(config)
      .then(function (response) {
        const data = response.data;

        const building1 = data.buildings.filter(building => {
          const regex = /Building 1/g;
          const regex2 = /Option/g;
          return (
            building.Phase === 1 &&
            building.Address === "Building 1" &&
            building.Address.search(regex2) < 0
          );
        })[0];
        const building2 = data.buildings.filter(building => {
          const regex = /Building 2/g;
          return building.Phase === 1 && building.Address === "Building 2";
        })[0];
        const building3 = data.buildings.filter(building => {
          const regex = /(Option 2)/g;
          return building.Phase === 1 && building.Address.search(regex) > 0;
        })[0];
        const building4 = data.buildings.filter(building => {
          const regex = /Building 1/g;
          return building.Phase === 2 && building.Address === "Building 1";
        })[0];
        const building5 = data.buildings.filter(building => {
          const regex = /Building 2/g;
          return building.Phase === 2 && building.Address === "Building 2";
        })[0];
        const building6 = data.buildings.filter(building => {
          const regex = /Building 3/g;
          return building.Phase === 2 && building.Address === "Building 3";
        })[0];

        const building1Specs = [
          ...building1.Specifications.map(spec => [spec.Label, spec.detail]),
          ["Building Area", building1.Area],
        ];
        const building2Specs = [
          ...building2.Specifications.map(spec => [spec.Label, spec.detail]),
          ["Building Area", building2.Area],
        ];
        const building3Specs = [
          ...building3.Specifications.map(spec => [spec.Label, spec.detail]),
          ["Building Area", building3.Area],
        ];
        const building4Specs = [
          ...building4.Specifications.map(spec => [spec.Label, spec.detail]),
          ["Building Area", building4.Area],
        ];
        const building5Specs = [
          ...building5.Specifications.map(spec => [spec.Label, spec.detail]),
          ["Building Area", building5.Area],
        ];
        const building6Specs = [
          ...building6.Specifications.map(spec => [spec.Label, spec.detail]),
          ["Building Area", building6.Area],
        ];

        const mapDataCMS = data.maps[0].Map;

        setContactData(data.contacts);
        setProjectLogo(data.logo.url);
        setProjectDescription({
          name: data.Name,
          projectName: data.Name,
          title: `About ${data.Name}`,
          filePrefix: data.filePrefix,
          logo: data.logo.url,
          address1: building1.Address,

          area1: building1.Area.toLocaleString(),

          totalArea: data.totalArea.toLocaleString(),
          occupancy: data.occupancy,
          bodyCopy: data.Description.split("\n")
            .filter(line => line.split("").length > 0)
            .map(line => `<p>${line}</p>`)
            .join(""),
          projectPDF: cmsEndpoint + data.project_pdf.url,
        });

        // const imagesForGallery = data.Gallery.map((image) => [image.url, image.alternativeText]);
        setLandingImages({
          data: data?.slide?.slides.map(slide => [slide.url, slide.alternativeText, slide.caption]),
        });

        setNotes({
          landlord: {
            notes: data.LandlordsWork,
            title: "LANDLORD'S WORK",
          },
          amenities: {
            notes: data.Amenities,
            title: "HIGHLIGHTS",
          },
        });

        mapDataCMS.map((item, index) => {
          if (item.name === "Location") {
            setLocationMapData(item);
          }
          if (item.name === "Area") {
            setAreaMapData(item);
          }
          if (item.name === "Site") {
            setSiteMapData(item);
          }
        });

        setGalleryImages({
          // data: imagesForGallery,
          data: data.Gallery.map(image => [image.url, image.alternativeText]),
        });

        setBuildingSpecifications({
          building1: {
            data: building1Specs,
            name: building1.Address,
            phase: building1.Phase,
          },
          building2: {
            data: building2Specs,
            name: building2.Address,
            phase: building2.Phase,
          },
          building3: {
            data: building3Specs,
            name: building3.Address,
            phase: building3.Phase,
          },
          building4: {
            data: building4Specs,
            name: building4.Address,
            phase: building4.Phase,
          },
          building5: {
            data: building5Specs,
            name: building5.Address,
            phase: building5.Phase,
          },
          building6: {
            data: building6Specs,
            name: building6.Address,
            phase: building6.Phase,
          },
        });

        setBuildingData({
          totalArea: data.totalArea.toLocaleString(),
          occupancy: data.occupancy,
          building1: {
            address: data.buildings[0].Address,
            area: data.buildings[0].Area.toLocaleString(),
            pdf: cmsEndpoint + data.buildings[0].PDF?.url,
          },
        });
      })
      .catch(function (error) {
        console.log(error);

        // setErrorText(error.message);
      });
    setLoadingData(false);
  }, []);

  const theme = useTheme();
  const showTextNav = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div id="StoneyNorthApp" className="App">
      <Container disableGutters align={"center"}>
        {showTextNav && (
          <Menu
            logo={projectLogo}
            menuData={menuData}
            handleSubMenuClick={handleSubMenuClick}
            subMenuState={subMenuState}
            handleMenuClick={handleMenuClick}
          />
        )}
        {!showTextNav && (
          <DrawerMenu
            logo={projectLogo}
            scrollToTop={scrollToTop}
            convertColor={convertColor}
            theme={theme}
            menuData={menuData}
            cmsEndpoint={cmsEndpoint}
          />
        )}
        <ContentContainer disableGutters maxWidth="xl">
          <Routes>
            <Route path="/" element={<Home projectDescription={projectDescription} />} />
            <Route path="advantages" element={<Advantages />} />
            <Route
              path="leasing"
              element={
                <Leasing
                  highlights={notes.amenities}
                  buildingSpecifications={buildingSpecifications}
                  siteMapData={siteMapData}
                  cmsEndpoint={cmsEndpoint}
                />
              }
            />
            <Route path="maps/location" element={<Maps map={locationMapData} />} />
            <Route path="maps/area" element={<Maps map={areaMapData} />} />
            <Route path="maps" end element={<Navigate replace to="/maps/location" />} />
            <Route path="contact" element={<Contact data={contactData} />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </ContentContainer>
        <Footer />
      </Container>
    </div>
  );
}
const ContentContainer = styled(Container)`
  margin-top: 60px;
  ${props => props.theme.breakpoints.up("sm")} {
    margin-top: 0;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    margin-top: 0;
  }
`;
export default App;
