import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/";
import { responsiveFontSizes } from "@mui/material/styles";

const font = "'Montserrat', sans-serif";
const titleFont = font;

const defaultTheme = createMuiTheme({
  maxWidth: "1920px",
  activeColor: "#6098AF",
  palette: {
    primary: {
      main: "rgba(197,32,49,1)", //Enright Red
      dark: "rgba(4, 62, 95, 1)",
      red: "rgba(168, 53, 58, 1)",

      light: "rgba(192, 202, 200, 1)",
      hover: "rgba(168, 53, 58, 1)",
      selected: "rgba(192, 202, 200, 0.54)",
      contrastText: "#fff",
      footer: "rgb(3,44,67)",
      bullet: "rgba(192, 202, 200,1)",
    },
    secondary: {
      dark: "rgba(112,125,132,1)",
      main: "#466566",
      light: "rgba(245, 245, 245,1)",
      highlight: "#E6781F",
    },
    grey: { backgroundGrey: "rgba(107,108,111,1)" },
    common: {
      lightBlack: "#f5f6f6",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: font,

    // fontSize: 12,
  },
});

export const theme = createMuiTheme({
  ...defaultTheme,
  components: {
    // Style sheet name
    MuiContainer: {
      root: {
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
    },

    MuiButton: {
      // Name of the rule
      contained: {
        color: defaultTheme.palette.common.white,
        backgroundColor: defaultTheme.palette.primary.slate,
      },
      root: {
        borderRadius: 21,
        height: 42,
      },
      label: {
        // Some CSS
        fontSize: ".8rem",
        fontStyle: "italic",
      },
    },

    MuiInputBase: {
      input: {
        fontSize: "1rem",
        fontFamily: "Helvetica",
      },

      inputMultiline: {
        lineHeight: "1.5rem",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "1.2rem",

        // color: "red",
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: font,
    h1: {
      color: defaultTheme.palette.primary.slate,
      fontSize: "1.25rem",
      fontFamily: titleFont,
      fontWeight: 700,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "2.0rem",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "2.0rem",
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: "2.5rem",
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: "2.5rem",
      },
    },
    h2: {
      fontSize: "1rem",
      fontFamily: titleFont,
      fontWeight: 800,
      textTransform: "uppercase",
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1.25rem",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.5625rem",
      },
      // [defaultTheme.breakpoints.up("lg")]: {
      //   fontSize: "1.25rem",
      // },
      // [defaultTheme.breakpoints.up("xl")]: {
      //   fontSize: "1.25rem",
      // },
    },
    //   h3: {
    //     // whiteSpace: "nowrap",
    //     fontSize: "1.3rem",
    //     textTransform: "uppercase",
    //     fontFamily: titleFont,
    //     fontWeight: 700,
    //     [defaultTheme.breakpoints.up("sm")]: {
    //       fontSize: "1.5rem",
    //     },
    //     [defaultTheme.breakpoints.up("md")]: {
    //       fontSize: "1.8rem",
    //     },
    //     [defaultTheme.breakpoints.up("lg")]: {
    //       fontSize: "2.0rem",
    //     },
    //   },
    //   h4: {
    //     fontSize: "1.3rem",
    //     fontFamily: titleFont,
    //     fontWeight: 700,
    //     textTransform: "uppercase",
    //   },
    //   h5: {
    //     // whiteSpace: "nowrap",
    //     fontSize: "1.4107142857142856rem",
    //     textTransform: "uppercase",
    //     fontFamily: titleFont,
    //     fontWeight: 700,
    //     [defaultTheme.breakpoints.up("sm")]: {
    //       fontSize: "1.2rem",
    //     },
    //     [defaultTheme.breakpoints.up("md")]: {
    //       fontSize: "1.6867rem",
    //     },
    //     [defaultTheme.breakpoints.up("lg")]: {
    //       fontSize: "1.4rem",
    //     },
    //   },
    //   h6: {
    //     fontFamily: titleFont,
    //     fontWeight: 700,
    //     // whiteSpace: "nowrap",
    //     // textTransform: "uppercase",
    //   },
    body1: {
      maxWidth: defaultTheme.breakpoints.values.lg,
      lineHeight: "1.875rem",
    },
  },
});
