//TODO combine these into a single object
export const menuData = [
  {
    name: "Advantages",
    title: "Learn about the advantages of the Stoney North location",
    to: "/advantages",
    id: "",
  },
  {
    name: "Maps",
    title: "Park 72 Maps",
    to: "/maps",
    id: "mapsLink",
    subMenu: [
      {
        name: "Location Map",
        title: "Park 72 Location Map",
        to: "/maps/location",
      },
      {
        name: "Area Map",
        title: "Park 72 Area Map",
        to: "/maps/area",
      },
    ],
  },
  {
    name: "Leasing",
    title: "Park 72 Leasing Information",
    to: "/leasing",

    id: "leasingLink",
    // subMenu: [
    //   {
    //     name: "Specifications",
    //     title: "Park 72 Specifications",
    //     to: "/leasing",
    //   },
    //   {
    //     name: "Leasing Plan",
    //     title: "Park 72 Leasing Plan",
    //     to: "/leasing",
    //   },
    // ],
  },
  // {
  //   name: "Gallery",
  //   title: "Park 72 Image Gallery",
  //   to: "/gallery",
  //   id: "",
  // },
  {
    name: "Contact",
    title: "Contact us about Park 72",
    to: "/contact",
    id: "",
  },
];
