import React from "react";

import { Grid, Typography } from "@mui/material";

export default function Facts() {
  return (
    <Grid container backgroundColor="primary.main" justifyContent={"space-evenly"} p={5}>
      {[
        {
          title: "Economy",
          copy: `Alberta’s economy is forecast to grow at an annual rate of +3.6% from 2023 – 2025. Relative to the other provinces in the country, Alberta ranks #1 in expected Real GDP growth.`,
        },
        {
          title: "Population",
          copy: `The population in Alberta is 
        projected to grow at an annual average rate of +1.4% from 2020 – 2025.`,
        },
        {
          title: "Employment",
          copy: `Total employment is expected to grow by +272,600 jobs over 2022 – 2025, 
        for an annual average growth rate of +2.4%. Alberta ranks first overall 
        among provinces by this metric.`,
        },
      ].map(({ title, copy }) => {
        return (
          <Grid key={copy} item xs={12} sm={4} p={2}>
            <Typography variant={"h2"} color="common.white">
              {title}
            </Typography>
            <Typography color="common.white">{copy}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}
